import './App.css';
import {useEffect, useState} from "react";
import axios from "axios";

function App() {
    const baseApiUrl = "https://crawler.maik.software"
    const [championships, setChampionships] = useState({});
    const [selectedChampionship, setSelectedChampionship] = useState("")
    const [clubs, setClubs] = useState([])
    const [selectedClub, setSelectedClub] = useState("")
    const [isButtonEnabled, setIsButtonEnabled] = useState(true)

    useEffect(() => {
        axios.get(`${baseApiUrl}/championships`)
            .then(response => {
                setChampionships(response.data);
            })
            .catch(error => {
                console.log(error)
            });
    }, []);

    useEffect(() => {
        if (championships[selectedChampionship] && championships[selectedChampionship].clubs) {
            console.log(selectedChampionship)
            setClubs(championships[selectedChampionship].clubs)
        }
    }, [selectedChampionship, championships])

    useEffect(() => {
        console.log(clubs)
        setSelectedClub(clubs[0])
    }, [clubs])

    const downloadFile = () => {
        setIsButtonEnabled(false)
        const today = new Date()
        const year = today.toLocaleString("default", {year: "2-digit"})
        const month = today.toLocaleString("default", {month: "2-digit"})
        const day = today.toLocaleString("default", {day: "2-digit"})

        const fileName = `${year}${month}${day}_${selectedClub}`

        console.log(fileName)
        console.log(selectedChampionship)
        console.log(selectedClub)
        axios.post(`${baseApiUrl}/create-file`, {
            club: selectedClub,
            championship: selectedChampionship
        }, {
            responseType: "blob"
        }).then(response => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement("a")
            link.href = href



            link.setAttribute("download", fileName + '.docx');

            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setIsButtonEnabled(true)
        })
    }


    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <label>Wettbewerb: </label>
                    <select onChange={e => setSelectedChampionship(e.target.value)}>
                        {Object.entries(championships).map((championship) => (
                            <option value={championship[0]}>{championship[0]}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Mannschaft: </label>
                    <select onChange={e => setSelectedClub(e.target.value)}>
                        {clubs.map((club) => (
                            <option value={club.name}>{club.name}</option>
                        ))}
                    </select>
                </div>

                <button onClick={() => downloadFile()} disabled={!isButtonEnabled}>Datei generieren</button>

            </header>
        </div>
    );
}

export default App;
